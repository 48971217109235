import React, { useState, useEffect, useRef } from "react";

//import client from '../contentfulClientToken';

import { Link } from 'react-router-dom';

export default function Analog({ images }) {
    const [imagesAnalog, setImagesAnalog] = useState([]);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isAllLoaded, setIsAllLoaded] = useState(false);
    const [loadedCount, setLoadedCount] = useState(0);
    const [randomPosition, setRandomPosition] = useState({ top: 0, left: 0 });
    const intervalRef = useRef(null);
    const [isFaded, setIsFaded] = useState(false);

    // Initialize `imagesAnalog` when the `images` prop changes
    useEffect(() => {
        if (images.length > 0) {
            setImagesAnalog(images);
        }
    }, [images]);

    const handleMouseDownGradient = () => setIsFaded(true);
    const handleMouseUpGradient = () => setIsFaded(false);

    const startInterval = () => {
        setIsFaded(false);
        if (imagesAnalog.length > 0) {
            intervalRef.current = setInterval(() => {
                setCurrentImageIndex((prevIndex) => {
                    const nextIndex = (prevIndex + 1) % imagesAnalog.length;
                    generateRandomPosition(); // Update position on index change
                    return nextIndex;
                });
            }, 50); // Update image every 50ms
        }
    };

    const stopInterval = () => {
        setIsFaded(true);
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    };

    const generateRandomPosition = () => {
        const randomTop = Math.random() * 80; // Limit within 80% of viewport height
        const randomLeft = Math.random() * 80; // Limit within 80% of viewport width
        setRandomPosition({ top: `${randomTop}vh`, left: `${randomLeft}vw` });
    };

    const handleImageLoad = () => {
        setLoadedCount((prevCount) => {
            const newCount = prevCount + 1;
            if (newCount === imagesAnalog.length) {
                setIsAllLoaded(true);
            }
            return newCount;
        });
    };

    useEffect(() => {
        if (isAllLoaded) {
            startInterval();
        }
        return () => stopInterval(); // Cleanup interval on unmount
    }, [isAllLoaded]);

    const getImageUrl = (image) => {
        let width = 550; // default width
        if (windowWidth < 768) {
            width = 650; // width for mobile devices
        }
        return `https:${image.url}?w=${width}`;
    };

    const handleMouseDown = (event) => {
        event.preventDefault(); // Prevent default browser behavior
        stopInterval();
    };

    const handleMouseUp = (event) => {
        event.preventDefault(); // Prevent default browser behavior
        startInterval();
    };

    return (
        <div id="analog">

            <div
                className={`gradient ${isFaded ? "faded" : ""}`}
            ></div>
            <div className="pictures-flashing-container">
                {images.length > 0 &&
                    images.map((image, index) => (
                        <div key={index}>
                            <img
                                src={getImageUrl(image)}
                                alt={image.title}
                                style={{
                                    display: index === currentImageIndex ? "block" : "none",
                                }}
                                onLoad={handleImageLoad}
                                onMouseDown={handleMouseDown}
                                onMouseUp={handleMouseUp}
                                onTouchStart={handleMouseDown}
                                onTouchEnd={handleMouseUp}
                            />
                            {index === currentImageIndex && (
                                <div className="current-image-title">
                                    {image.title.split(" - ").map((part, i) => (
                                        <p key={i}>{part}</p>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}

                <ul className="menu">
                    <li>
                        <Link to="/digital">digital</Link>
                    </li>
                    <li>
                        <Link to="/">info</Link>
                    </li>
                </ul>
            </div>
        </div>
    );
}
