import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Analog from "./components/Analog";
import Digital from "./components/Digital";
import client from "./contentfulClientToken"; // Ensure you import your Contentful client
import './App.scss';
function App() {

    window.addEventListener(
        'contextmenu',
        function (e) {
            e.preventDefault();
        },
        false
    );

    const [imagesAnalog, setImagesAnalog] = useState([]);
    const [imagesDigital, setImagesDigital] = useState([]);

    // Fetch Analog Pictures
    const fetchPicturesAnalog = async () => {
        try {
            const entryId = "6yWtzZS8veCBNuidxVMHeG"; // Replace with your actual entry ID
            const response = await client.getEntry(entryId);

            if (response.fields && response.fields.pictures) {
                const fetchedImages = response.fields.pictures.map((picture) => ({
                    url: picture.fields.file.url,
                    title: picture.fields.title,
                }));
                setImagesAnalog(fetchedImages);
            } else {
                console.warn("No images found in response.");
            }
        } catch (error) {
            console.error("Error fetching images:", error);
        }
    };

    // Fetch Digital Pictures
    const fetchPicturesDigital = async () => {
        try {
            const entryId = "62j4KYOawgsi8hvbXsAMmU"; // Replace with your actual entry ID
            const response = await client.getEntry(entryId);

            if (response.fields && response.fields.pictures) {
                const fetchedImages = response.fields.pictures.map((picture) => ({
                    url: picture.fields.file.url,
                    title: picture.fields.title,
                }));
                setImagesDigital(fetchedImages);
            } else {
                console.warn("No pictures found in response.");
            }
        } catch (error) {
            console.error("Error fetching images:", error);
        }
    };

    // Fetch images on component mount
    useEffect(() => {
        fetchPicturesAnalog();
        fetchPicturesDigital();
    }, []);

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/analog" element={<Analog images={imagesAnalog} />} />
                <Route path="/digital" element={<Digital images={imagesDigital} />} />
            </Routes>
        </Router>
    );
}

export default App;