import React, { useState, useEffect } from "react";

import { Link } from 'react-router-dom';
import client from '../contentfulClientToken';

export default function Home() {


    const [images, setImages] = useState([]);


    const [isFaded, setIsFaded] = useState(false);

    const handleMouseDownGradient = () => {
        setIsFaded(true);
    };

    const handleMouseUpGradient = () => {
        setIsFaded(false);
    };



    return (
        <div id="home">

            <div
                className='gradient'
            ></div>

            <ul className='menu'>
                <li><Link to="/analog">analog</Link></li>
                <li><Link to="/digital">digital</Link></li>
            </ul>

            <div className="studio-picture">
                <img src="../content/Oncle-Jean-Baptiste_labo-photo_1905.jpg" alt="Photography lab Algier 1905" />
                <img src="../content/Tampon-JB_GAMBINI.png" alt="Stamp JB Gambini 1905" className="stamp-above" />
            </div>

            {/*  <div className="stamp">
                
            </div> */}

            <div className="description">
                <p>
                    I used to think that the quest to embellish reality through retouching was a symptom of the digital age, but I reassessed that thought after seeing this black-and-white photo my father once sent me.
                    Taken in 1905 in the photolab of his uncle in Algiers, the image shows men stylising portraits using analog tools. Is it possible to enhance a picture without inherently distorting its content?
                    Post-production was already embedded into the final product, making the medium fail to depict reality.
                    _
                    The pictures displayed in the <Link style={{ color: 'black' }} to="/analog">analog</Link> section were taken on film with a Mamiya RZ67 between 2015 and 2021.
                    Still my favorite camera to this day, carrying it all over the place was a pain, making the act of photographing as tangible as it gets.
                    But why so much tech*nostalgia? Shooting on film was an enriching process but it didn't reveal the truth.
                    As most of the images we consume today are viewed through screens, this material is inevitably subjected to digitisation.
                    Once developed, scanned, and stored on hardware, images loose their materiality, reduced to sequences of 0s and 1s.
                    This grain, texture or color saturation are becoming filters. _
                    The <Link style={{ color: 'black' }} to="/digital">digital</Link> series embraces crossing the line, allowing AI to remodel the data, giving it a new life.
                </p>
            </div>

        </div>
    );
}